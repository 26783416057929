<template>
  <ul
    v-chakra
    :maxW="['300px', '450px']"
    v-bind="$attrs"
  >
    <li
      v-for="notification in props.notifications"
      :key="notification.id"
      class="cursor-pointer flex gap-2 p-3 not-last:border-b not-last:border-black not-last:border-opacity-10 hover:bg-gray-100 active:bg-gray-200"
      :class="{ 'bg-dgreen2 bg-opacity-50	hover:bg-opacity-80 active:bg-opacity-100': notification.isRead === 0 || notification.isRead === false }"
      @click="emit('onClick', notification)"
    >
      <div>
        <BadgeUnread :show="notification.isRead === 0 || notification.isRead === false">
          <inline-svg
            :src="iconNotification"
            height="15"
            width="15"
            fill="black"
          />
        </BadgeUnread>
      </div>
      <div
        v-chakra
        class="flex flex-col gap-1"
        :fontSize="['16px', '18px']"
      >
        <p
          class="font-medium"
          :class="{
            'line-clamp-2': props.ellipsis !== false,
          }"
        >
          {{ notification.title }}
        </p>
        <p
          v-show="!!notification.content"
          :class="{
            'text-dgray2': true,
            'line-clamp-2': props.ellipsis !== false,
          }"
        >
          {{ notification.content }}
        </p>

        <p class="text-dgray3">
          {{ formatTimeSince(notification.createdAt) }}
        </p>
      </div>
    </li>
  </ul>
</template>

<script >
import iconNotification from '@/assets/icons/icon-notification.svg';
import BadgeUnread from './badge-unread.vue';
import { formatTimeSince } from '@/utils/format-time-since';
const __sfc_main = {};
__sfc_main.props = ['notifications', 'ellipsis'];

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  return {
    iconNotification,
    formatTimeSince,
    props,
    emit
  };
};

__sfc_main.components = Object.assign({
  BadgeUnread
}, __sfc_main.components);
export default __sfc_main;
</script>
