import { useQueryClient, useMutation } from '@tanstack/vue-query'
import { useActions } from '@/stores'
import { useRouter } from 'vue2-helpers/vue-router'

const parseRedirectPath = ({ url, redirectPage, redirectType, redirectId }) => {
  if (url) return url
  if (redirectPage === 'profile' && redirectType === 'history-transaction') {
    return {
      name: 'client.profile.detailHistoryTransaction',
      params: {
        transactionId: redirectId,
      },
    }
  }
  return null
}

export const useNotification = () => {
  const queryClient = useQueryClient()
  const router = useRouter()

  const action = useActions(
    'clients',
    ['readNotification'],
  )

  const { mutateAsync } = useMutation({
    mutationFn: (notifId) => action.readNotification(notifId),
    // When mutate is called:
    onMutate: async(notifId) => {
      await queryClient.cancelQueries({ queryKey: ['useFetchPopupNotifications'] })
      const previousTodos = queryClient.getQueryData(['useFetchPopupNotifications'])
      queryClient.setQueryData(['useFetchPopupNotifications'], (old) => old?.map((item) => {
        if (item.id === notifId) return { ...item, isRead: true }
        return item
      }))
      return { previousTodos }
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(['useFetchPopupNotifications'], context.previousTodos)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['useFetchPopupNotifications'] })
    },
  })
  const handleReadNotification = async(notification) => {
    await mutateAsync(notification.id)
    const redirectPath = parseRedirectPath(notification)
    if (!redirectPath) return
    router.push(redirectPath)

    // this.readNotification(item.id).then(() => {
    //   if (item.title.toLowerCase().includes('personalized meal plan')) {
    //     return router.push({
    //       name: 'meal-plan.profile-gizi',
    //     })
    //   }
    //   if (this.role === 'client' && item.redirectPage === 'progress-tracker' && item.redirectType === 'feedback') {
    //     return router.push({
    //       name: 'client.progress-tracker.detail',
    //       params: { progressId: item.redirectId },
    //       query: { stepi: 2 },
    //     })
    //   } else if (this.role === 'nutritionist' && item.redirectPage === 'progress-tracker' && item.redirectType === 'feedback') {
    //     return router.push(item.url)
    //   }
    //   if (item.redirectPage === 'content-and-challenge') {
    //     return router.push({
    //       name: 'content-challenge.detail',
    //       params: { id: item.redirectId },
    //     })
    //   }
    // })
  }

  return {
    handleReadNotification,
  }
}